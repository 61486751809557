<div class="header">
  <div></div>
  <button (click)="exportAsCSV()" type="button">{{ "common.export" | translate }}</button>
</div>
<table>
  <tr class="heads">
    <th style="width: 100px">#</th>
    <th><div>PLOs</div></th>
    <th><div>PLO Benchmark</div></th>
    <th>
      <div>Plo Achievement</div>
    </th>
    <th>
      <div></div>
    </th>
  </tr>
  @for (plo of plos(); track plo; let i = $index,last = $last) {

  <tr
    class="row"
    [style.border-bottom]="!last ? '1px solid #E8EBED9C' : 'none'"
  >
    <td style="width: 100px">{{ i + 1 }}</td>

    <td>
      {{ plo.name }}
    </td>
    <td class="benchmark">
      <p>{{ ploBenchmark() }}%</p>
    </td>

    @if (plo.achievement === -1) {
    <td class="unmeasured">
      <p>Unmeasured</p>
    </td>
    } @else {
    <td>
      <app-progress-bar
        [config]="{ percent: plo.achievement, benchmark: ploBenchmark() }"
      ></app-progress-bar>
    </td>
    }@if (plo.achievement === -1) { } @else {
    <td class="arrow" (click)="this.selectPlo(plo)">
      <figure><img src="assets/svgs/icons/right-arrow.svg" alt="" /></figure>
    </td>
    }
  </tr>
  }
</table>
